



























































































































import { Component, Mixins, PropSync } from "vue-property-decorator";
import { base_class_pantalla } from "@/shared/classes/base-class-pantallas";
import { respuestas } from "@/shared/dtos/respuestas";
@Component({
  components: {},
})
export default class config_encuestas_formulario_respuestas extends Mixins(
  base_class_pantalla
) {
  @PropSync("datasource") datasourcesync!: respuestas[];
  public respu: respuestas[] = [];
  public editedItem: respuestas = new respuestas({ nombre: "", puntuacion: 0 });
  public defaultItem: respuestas = new respuestas({
    nombre: "",
    puntuacion: 0,
  });
  public dialog: boolean = false;
  public dialogDelete: boolean = false;
  public dialog_masive_insert: boolean = false;
  public textdialog_masive_insert: string = "";
  public editedIndex = -1;
  public headers = [
    {
      text: "Respuesta",
      value: "nombre",
    },
    { text: "Puntuación", value: "puntuacion" },
    { text: "", value: "actions", sortable: false },
  ];

  created() {
    this.nombre_pantalla = "Respuestas";
    this.toolbar_texto_editar = "Editar respuesta";
    this.toolbar_texto_crear = "Crear nueva respuesta";
    this.es_edicion = Number.parseInt(this.$route.params.id) === -1;
  }

  public get formTitle() {
    return this.editedIndex === -1
      ? "Crear nueva respuesta"
      : "Editar respuesta";
  }

  /*public get datasource() {
    return respuestasModule.respuestass;
  }*/

  public editItem(item: respuestas) {
    this.editedIndex = this.datasourcesync.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialog = true;
  }

  public deleteItem(item: respuestas) {
    this.editedIndex = this.datasourcesync.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialogDelete = true;
  }

  public deleteItemConfirm() {
    this.datasourcesync.splice(this.editedIndex, 1);
    this.closeDelete();
  }

  public close() {
    this.dialog = false;
    this.dialog_masive_insert = false;
    this.textdialog_masive_insert = "";
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  public closeDelete() {
    this.dialogDelete = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  public save() {
    if (this.editedIndex > -1) {
      Object.assign(this.datasourcesync[this.editedIndex], this.editedItem);
    } else {
      this.datasourcesync.push(this.editedItem);
    }
    this.close();
  }
  public save_masive() {
    let respt = this.textdialog_masive_insert.split("\n");
    for (let i = 0; i < respt.length; i++) {
      if (respt[i] !== "") {
        let puntuacion = 0;
        if (respt[i].trim() === "1. Sí") {
          puntuacion = 1.5;
        }
        this.datasourcesync.push(
          new respuestas({ nombre: respt[i], puntuacion: puntuacion })
        );
      }
    }
    this.close();
  }
}
